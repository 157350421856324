<template>
  <div>
    <div style="margin: 20px 0 0 20px"><MyBreadcrumb></MyBreadcrumb></div>
    <div class="ali-stat">
      <p>{{dataInfo.lastweek_starttime}}-{{dataInfo.lastweek_endtime}}</p>
      <p class="cont">{{dataInfo.info}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "aliStat.vue",
  data(){
    return{
      dataInfo:{},
      week:0
    }
  },
  created(){
    if(this.$route.query.week){
      this.week = this.$route.query.week;
    }
    this.getData();
  },
  methods:{
    getData(){
      var params = {
        week:this.week
      };
      this.api.system.systemAliStat(params).then((res)=>{
        this.dataInfo = res;
      })
    },
  }
}
</script>

<style scoped lang="scss">
.ali-stat{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  font-size: 14px;

  .cont{
    margin-top: 10px;
    line-height: 26px;
  }
}
</style>
